const status_code = {
  0: "Offline",
  1: "Free",
  2: "Members",
  3: "Private",
  11: "ERROR",
  12: "INVALID_USER",
  13: "ACCESS_DENIED",
};

const compare = (a, b) => (a.time < b.time ? -1 : a.time > b.time ? 1 : 0);

function convertSeconds(d) {
  let h = Math.floor(d / 3600);
  let m = Math.floor((d % 3600) / 60);
  // let s = Math.floor(d % 3600 % 60);
  if (isNaN(h)) return "No data";
  return `${h}H:${m < 10 ? "0" + m : m}M`;
}

function calculate({ r, day, json }) {
  let temp = [];
  let sites = {};
  r.forEach((m) => {
    sites[m.site] = sites[m.site] || {};
    if (m.list != null && m.list.length > 0) {
      sites[m.site] = calculateTime(m.list, m.model, m.site, json);
      temp = [...temp, ...sites[m.site].temp];
    }
  });
  let result = new_calculateOnlineTime(temp, day, json);
  Object.keys(sites).forEach((site) => delete sites[site].temp);
  return { result, sites };
}

function calculateTime(list, model, site, json) {
  if (list[list.length - 1] == undefined) {
    console.log("ERROR", list, model, site);
    return [];
  }
  if (json && list[list.length - 1]["status"] > 0)
    list.push({ status: 0, time: json.stop });
  if (site !== "all" && list[list.length - 1]["status"] > 0)
    list.push({ status: 0, time: moment().unix() });

  list.sort(compare);
  let current_state = -1;
  let current_stamp = 0;
  let total = {};
  let temp = [];
  let per = { one: { total: 0, type: {} }, two: { total: 0, type: {} } };
  list.forEach((i) => {
    if (current_state < 1 && i.status < 1) {
      current_stamp = i.time;
    }
    if (current_stamp < 1) current_stamp = i.time;
    if (current_state < 0) current_state = i.status;

    if (current_state != i.status) {
      if (current_state != 0) {
        if (total[status_code[current_state]] == undefined)
          total[status_code[current_state]] = 0;
        let time = parseFloat(i.time - current_stamp);
        total[status_code[current_state]] += time;

        temp.push({ time: current_stamp, state: 1 });
        temp.push({ time: i.time, state: -1 });

        let date = new Date(i.time * 1000);
        let d = date.getDate();
        let p = d > 15 ? "two" : "one";
        if (per[p].type[status_code[current_state]] == undefined)
          per[p].type[status_code[current_state]] = 0;
        per[p].type[status_code[current_state]] += time;
        per[p].total += time;
      }
      current_state = i.status;
      current_stamp = i.time;
    }
  });
  return { times: total, temp, per };
}

function new_calculateOnlineTime(ranges, day, json, format) {
  ranges.sort(compare);
  if (json && ranges.length && ranges[ranges.length - 1]["state"] > 0) {
    let r = { state: -1, time: json.stop };
    ranges.push(r);
  }
  let state = 0;
  let start = 0;
  let result = 0;
  let intervals = [];
  let interval_stamps = {};
  let days = {};
  const rk = Object.keys(ranges);
  rk.forEach((i) => {
    let event = ranges[i];
    // console.log()
    state += event.state;

    if (state > 0) {
      if (start == 0) {
        start = event.time;
      }
    } else {
      if (start != 0) {
        if (event.time > start) {
          let starting = moment.unix(start);
          let ending = moment.unix(event.time);
          result += ending.diff(starting, "seconds");
          let startS = starting.format(format ? format + " HH:mm" : "HH:mm");
          let stopS = ending.format(format ? format + " HH:mm" : "HH:mm");
          let int =
            startS +
            " - " +
            stopS +
            " <span class='font-green-seagreen'>" +
            convertSeconds(ending.diff(starting, "seconds")) +
            "</span>";

          let d = starting.format(format || "D");
          if (interval_stamps[d] == undefined) interval_stamps[d] = [];
          interval_stamps[d].push({ start, stop: event.time });
          intervals.push(int);
          if (day) {
            if (days[d] == undefined) days[d] = 0;
            days[d] += ending.diff(starting, "seconds");
          }
        }
        start = 0;
      }
    }
  });
  return { result, intervals, days, interval_stamps };
}

function calculateModelSites(list, json) {
  return new Promise((resolve, reject) => {
    console.log(list, json);
    if (list[list.length - 1] == undefined) {
      console.log("ERROR", list);
      return reject("list length is too short");
    }
    if (json && list[list.length - 1]["status"] > 0) {
      list.push({ status: 0, time: json.stop });
    }
    list.sort(compare);
    let current_state = -1;
    let current_stamp = 0;
    let months = {};
    let periods = {
      one: 0,
      two: 0,
    };
    list.map((i) => {
      if (current_state < 1 && i.status < 1) {
        current_stamp = i.time;
      }
      if (current_stamp < 1) current_stamp = i.time;
      if (current_state < 0) current_state = i.status;

      if (current_state != i.status) {
        if (current_state != 0) {
          let time = parseFloat(i.time - current_stamp);

          let date = new Date(i.time * 1000);

          let p = date.getDate() < 16 ? "one" : "two";
          let month = `${date.getMonth() + 1}-${date.getFullYear()}`;

          if (months[month] == undefined) months[month] = { one: 0, two: 0 };

          months[month][p] += time;
          periods[p] += time;
        }
        current_state = i.status;
        current_stamp = i.time;
      }
    });
    resolve({ months, periods });
  });
}

function accountState(url, account) {
  console.log("account", account);
  return new Promise((resolve, reject) => {
    axios
      .post(`${url}accountState`, account)
      .then((r) => resolve(r.data))
      .catch((e) => {
        reject(e);
      });
  });
}

const defaultStart = () => {
  return moment()
    .startOf("month")
    .subtract(11, "months")
    .startOf("month")
    .unix();
};

const defaultEnd = () => {
  return moment().unix();
};

function getOnline(url, start = defaultStart(), end = defaultEnd()) {
  return new Promise((resolve, reject) => {
    console.log(url);
    axios
      .get(`${url}onlinetimechache/${start}/${end}/true`)
      .then((r) => {
        // return resolve(r.data)
        const data = r.data;
        const studios = Object.keys(data.cache.totalOnline);
        let onlineModelTimes = {};
        let averageRate = {};
        studios.forEach((v) => {
          if (!onlineModelTimes[v])
            onlineModelTimes = Object.assign({}, onlineModelTimes, { [v]: {} });
          averageRate = Object.assign({}, averageRate, { [v]: {} });
        });
        const totalOnline = data.cache.totalOnline;
        const online = data.cache.sort;
        const studiosOnline = data.cache.studiosOnline;
        const assigned = data.assigned;
        return resolve({
          totalOnline,
          online,
          studiosOnline,
          assigned,
          averageRate,
          onlineModelTimes,
        });
      })
      .catch((e) => reject(e));
  });
}

let non_adult_accounts = [
  "twitter",
  "exclusive",
  "lovense",
  "youtube",
  "amazon",
];
let update_ids = {
  chr: "chr",
  xlv: "xlv",
  epl: "epl",
  dreamcam: "dmc",
  twitter: "twt",
  babestationcams: "bsc",
  mfc: "mfc",
  mfa: "mfa",
  stripchat: "str",
  chaturbate: "ctb",
  bonga: "bng",
  camsoda: "cms",
  cam4: "cm4",
  naked: "nkd",
  camplace: "cmp",
  camgams: "cmg",
  flirt4free: "f4f",
  camdolls: "cmd",
  lj: "ljm",
  smate: "stm",
  cams: "cam",
  adultwork: "adw",
  imlive: "iml",
  xlove: "xlc",
  xmodels: "xmd",
  camcontacts: "cmc",
  chatdf: "cgf",
  eurolive: "elv",
  extasy: "exc",
  xcams: "xcm",
  //exclusive: '',
  ifriends: "ifr",
  youtube: "ytb",
  amazon: "amz",
  exclusive: "non",
  lovense: "lvs",
  bim: "bim",
};
let sites_ids = {
  chr: "CherryTv",
  xlv: "XloveCam",
  epl: "Eplay",
  dmc: "Dreamcam",
  mfc: "MyFreeCams",
  mfa: "MFCAlerts",
  str: "Stripchat",
  ctb: "Chaturbate",
  bng: "Bonga",
  cms: "Camsoda",
  cm4: "Cam4",
  nkd: "Naked",
  cmp: "Camplace",
  cmg: "Camgams",
  f4f: "Flirt4Free",
  cmd: "CamDolls",
  ljm: "LiveJasmin",
  bim: "BimBim",
  stm: "Streamate",
  cam: "Cams",
  adw: "AdultWork",
  bsc: "babestationcams",
  iml: "Imlive",
  xlc: "Xlove",
  xmd: "Xmodels",
  cmc: "CamContacts",
  cgf: "ChatGF",
  elv: "EuroLive",
  exc: "Extasy",
  xcm: "XCams",
};

export default {
  calculate,
  accountState,
  getOnline,
  sites_ids,
  update_ids,
  non_adult_accounts,
};
