const defaultStart = () => {
  return moment()
    .startOf("month")
    .subtract(11, "months")
    .startOf("month")
    .unix();
};

const defaultEnd = () => {
  return moment().endOf("month").unix();
};

export async function getIncome(
  url,
  start = defaultStart(),
  end = defaultEnd()
) {
  let data = {
    start,
    end,
    type: "detailed", // basic | detailed
    sort: true, // sorts null events
    // models: q
  };
  return axios
    .post(url + "getIncomeCache", data)
    .then((list) => list.data)
    .catch((error) => {
      console.log(error);
      if (error.response) {
        toastr.error(error.response.data);
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
      } else if (error.request) {
        toastr.error(error.request);
        console.log(error.request);
      } else {
        console.log("Error", error.message);
      }
    });
}
